/** @module parameters */

/**
 * Initializes parameters functionalities.
 *
 * @return {Promise<void>}
 */
export async function parameters() {
    const parameters = document.querySelector('.parameters');

    // check if parameters is on current page
    if (parameters !== null) {
        let scrollOffset = 12,
            prevTableItem = document.querySelector('[data-prev-table-item]'),
            nextTableItem = document.querySelector('[data-next-table-item]'),
            parametersFixed = document.querySelector('.parameters__fixed'),
            parametersMain = document.querySelector('.parameters__main'),
            parametersFixedProduct = document.querySelectorAll('.parameters__fixed__product'),
            parametersMainTag = document.querySelectorAll('.parameters__main__tag'),
            parametersNavigation = document.querySelectorAll('.parameters__main__navigation');

        // adjust scrolling behaviour styles if the container is scrollable
        const resizeObserver = new ResizeObserver(() => {
            if (parametersMain.clientWidth !== parametersMain.scrollWidth) {
                // default scroll shadow
                parametersMain.classList.add('parameters__main--box-shadow');

                // show navigation arrows
                parametersNavigation.forEach((el) => {
                    el.classList.remove('parameters__main__navigation--hide');
                });
            } else {
                parametersMain.classList.remove('parameters__main--box-shadow');
                parametersFixed.classList.remove('parameters__fixed--box-shadow');

                parametersNavigation.forEach((el) => {
                    el.classList.add('parameters__main__navigation--hide');
                });
            }
        });

        /* eslint-disable no-inner-declarations */
        function addClassToSiblings(el, className) {
            for (const items of el.parentNode.children) {
                items.classList.toggle(className);
            }
        }

        function addClassToChildren(el, className) {
            for (const items of el.children) {
                items.classList.toggle(className);
            }
        }

        function addClassToSiblingsInMainTable(el) {
            let indexOfCurrentElement = Array.from(el.parentNode.children).indexOf(el),
                adjustingRow = document.querySelector(
                    `.parameters__main__body tr:nth-child(${indexOfCurrentElement + 1}) td`, // add +1 to make usable with nth-child selector
                );

            addClassToSiblings(adjustingRow, 'parameters__main__tag__status__active--hover');
        }

        function addClassToSiblingsInFixedTable(el) {
            let indexOfCurrentElement = Array.from(el.parentNode.children).indexOf(el),
                adjustingRow = document.querySelector(
                    `.parameters__fixed__body tr:nth-child(${indexOfCurrentElement + 1}) th`, // add +1 to make usable with nth-child selector
                );

            adjustingRow.classList.toggle('parameters__fixed__product__name--hover');
        }

        function checkIfStartOfScrollbarIsReached() {
            if (parametersMain.scrollLeft - scrollOffset < 0) {
                parametersFixed.classList.remove('parameters__fixed--box-shadow');
                parametersMain.classList.add('parameters__main--box-shadow');
            } else {
                parametersFixed.classList.add('parameters__fixed--box-shadow');
            }
        }

        function checkIfEndOfScrollbarIsReached() {
            if (parametersMain.offsetWidth + (parametersMain.scrollLeft + scrollOffset) > parametersMain.scrollWidth) {
                parametersMain.classList.remove('parameters__main--box-shadow');
                parametersFixed.classList.add('parameters__fixed--box-shadow');
            } else {
                parametersMain.classList.add('parameters__main--box-shadow');
            }
        }

        function checkStatusOfScrollbar() {
            if (parametersMain.offsetWidth + parametersMain.scrollLeft < parametersMain.scrollWidth) {
                parametersMain.classList.add('parameters__main--box-shadow');
            } else {
                parametersMain.classList.remove('parameters__main--box-shadow');
            }

            if (parametersMain.scrollLeft > 0) {
                parametersFixed.classList.add('parameters__fixed--box-shadow');
            } else {
                parametersFixed.classList.remove('parameters__fixed--box-shadow');
            }
        }
        /* eslint-enable no-inner-declarations */

        resizeObserver.observe(parametersMain);

        /////////////////////////////////////////////////////////////////////////////
        // navigation & box - shadow according to scrolling
        /////////////////////////////////////////////////////////////////////////////
        prevTableItem.addEventListener('click', () => {
            parametersMain.scrollLeft -= scrollOffset;
        });

        nextTableItem.addEventListener('click', () => {
            parametersMain.scrollLeft += scrollOffset;
        });

        // navigation scrolling behaviour mobile & desktop
        parametersMain.addEventListener(
            'scroll',
            () => {
                checkStatusOfScrollbar();
                checkIfStartOfScrollbarIsReached();
                checkIfEndOfScrollbarIsReached();
            },
            { passive: true },
        );

        /////////////////////////////////////////////////////////////////////////////
        // hover behaviour mostly in JS to select correct items over multiple tables
        /////////////////////////////////////////////////////////////////////////////
        parametersFixedProduct.forEach((el) => {
            el.addEventListener('mouseenter', () => {
                addClassToSiblingsInMainTable(el);
            });

            el.addEventListener('mouseleave', () => {
                addClassToSiblingsInMainTable(el);
            });
        });

        parametersMainTag.forEach((el) => {
            el.addEventListener('mouseenter', () => {
                addClassToSiblingsInFixedTable(el);
                addClassToChildren(el, 'parameters__main__tag__status__active--hover');
            });
            el.addEventListener('mouseleave', () => {
                addClassToSiblingsInFixedTable(el);
                addClassToChildren(el, 'parameters__main__tag__status__active--hover');
            });
        });
    }
}
