/**
 * Initializes front-page teaser jumper functionalities.
 *
 * @return {Promise<void>}
 */
export async function frontPageTeaser() {
    let scanJumper = document.querySelector('.scan_jumper');

    if (scanJumper !== null) {
        scanJumper.addEventListener('click', () => {
            Foundation.SmoothScroll.scrollToLoc('.intro__headline', {
                threshold: 50,
                offset: 100,
            });
        });
    }
}
